/*==========================================================================
Features
==========================================================================*/
.features__area {
    &-item {
        position: relative;
        z-index: 3;
        &::after {
            position: absolute;
            content: '';
            left: 0;
            bottom: 0;
            width: 100%;
            transition: all .4s ease-out 0s;
            height: 0;
            background: $primary-color;
            z-index: 0;
            opacity: 0;

        }
        img {
            width: 100%;
        }
        &-content {
            opacity: 0;
            position: absolute;
            transition: all .4s ease-out 0s;
            left: 50px;
            top: 50%;
            transform: translateY(-65%);
            z-index: -1;
            h3 {
                margin-bottom: 6px;
                a {
                    color: $white;
                }
            }
            p {
                color: $white;
            }
            &-icon {
                padding-bottom: 140px;
                a i {
                    color: $text-color-4;
                    border: 1px solid $text-color-9;
                    width: 50px;
                    height: 50px;
                    transition: all .4s ease-out 0s;
                    line-height: 50px;
                    text-align: center;
                    font-size: 20px;
                    border-radius: 50%;
                }
            }
        }
        &.active {
            &::after {
                opacity: 0.7;
                height: 100%;
            }
            & .features__area-item-content {
                z-index: 3;
                opacity: 1;
                transform: translateY(-50%);
            } 
        }
    }
}

@media (max-width: 1545px) {

    .features__area {
        &-item {
            &-content {
                &-icon {
                    padding-bottom: 90px;
                }
            }
        }
    }
}
@media (max-width: 1300px) {
    .features__area {
        &-item {
            &-content {
                &-icon {
                    padding-bottom: 80px;
                }
                left: 40px;
            }
        }
    }
}


