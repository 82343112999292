$body-font: 'Inter', sans-serif;
$heading-font: 'Ubuntu', sans-serif;
$body-color: #CFD0D0;
$white: #FFFFFF;
$primary-color: #F94D1D;
$primary-color: #F94D1D;
$text-color-1: #030404;
$text-color-2: #3F4144;
$text-color-3: #6F7173;
$text-color-4: #9FA0A1;
$text-color-5: #F8F8F8;
$text-color-6: #17181A;
$text-color-7: #0F1212;
$text-color-8: #121717;
$text-color-9: #6B91B0;
$border: #1E2323;
$border1: #101111;
$border2: #1D1E1E;
$border3: #272A2A;
$border4: #0C0F0F;
$shadow-1:rgba(0, 0, 0, 0.1);
$shadow-2:rgba(0, 0, 0, 0.07);
$shadow-3:0 0 60px rgb(255 255 255 / 7%);

@mixin mobile {
    @media screen and (max-width: 650px) {
      @content;
    }
  }
  @mixin tablet {
    @media screen and (max-width: 768px) {
      @content;
    }
  }
  @mixin laptop {
    @media screen and (max-width: 1200px) {
      @content;
    }
  }
  

  
  * {
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  
  .formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  
    .formWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      // margin: 25px 0 0 0;
      padding: 40px 60px;
      border-radius: 100px 100px 100px 0;
      color: $white;
      width: 650px;
  
      @include mobile {
        width: 65%;
        background: transparent;
        box-shadow: none;
        padding: 40px 20px;
      }
  
      .or {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-content: center;
        color: $white;
        margin: 20px 0;
        font-size: 0.8rem;
  
        hr {
          width: 40%;
          height: 1px;
          background-color: $white;
          border: none;
          align-self: center;
        }
      }
  
      .logo {
        color: $primary-color;
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      .loginWithGoogleButton {
        min-width: 300px;
        padding: 14px 10px;
        outline: none;
        border: 1px solid $white;
        border-radius: 15px;
        background-color: transparent;
        margin-bottom: 10px;
  
        display: flex;
        align-items: center;
        justify-content: center;
  
        color: $white;
        font-weight: 500;
  
        svg {
          width: 20px;
          margin-right: 10px;
        }
  
        &:focus {
          outline: none;
        }
      }
      .signIn {
        min-width: 300px;
        color: $white;
      }
  
      .title {
        font-size: 16px;
        font-weight: 300;
        margin-top: 10px;
        margin-bottom: 20px;
      }
  
      form {
        display: flex;
        justify-content: space-between;
        gap: 15px 0;
        
  
        input {

          padding: 14px 12px;
          outline: none;
          border: 1px solid $white;
          border-radius: 15px;
          background: transparent;
          color: $white;
          transition: 0.3s;
  
          &:hover,
          &:focus {
            transform: scale(1.03);
          }
          &::placeholder {
            color: $white;
          }
  
          @include mobile {
            &::placeholder {
              font-size: 14px;
            }
          }
        }
  
        .error {
          font-size: 14px;
          display: flex;
          justify-content: center;
          color: rgb(145, 0, 0);
        }
  
        label {
          width: max-content;
          display: flex;
          align-items: center;
          cursor: pointer;
  
          img {
            width: 24px;
            transition: 0.3s;
  
            &:hover {
              transform: scale(1.1);
            }
          }
  
          .avatarText {
            margin: auto 10px;
          }
        }
  
        button {
          background-color: $primary-color;
          padding: 15px 0px;
          box-shadow: none;
          border: none;
          border-radius: 15px;
          font-size: 14px;
          transition: 0.5s;
  
          &:hover {
            transform: scale(1.05);
          }
  
          @include mobile {
            color: black;
          }
        }
      }
      p {
        margin: 10px 0;
  
        .link {
          text-decoration: none;
          color: $primary-color;
          cursor: pointer;
  
          &:active {
            color: $white;
          }
        }
      }
    }
  }


  .card-container {
    width: 300px;
    margin: 0 auto;
    border: none;
  
    .card {
      width: 100%;
      height: 650px;
      transition: transform 0.5s ease;
      border: none;
      
      .card-front,
      .card-back {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: transform 0.5s ease;
      }
      .card-front, .card-back {
        background-color: $text-color-8;
      }
    }
  }
  


  .pading-left {
    padding-left: 0;
   }
  
  
   .pading-right {
    padding-right: 0;
   }
  


  @media (max-width: 768px) {

    .card-container .card {
      height: 850px;
  }

  .card-container .logIn {
    height: 650px !important;
}



    .pading-left {
      padding: 0;
     }
    
    
     .pading-right {
      padding: 0;
     }
  }



  .log-input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .tooltip-icon {
    margin-left: 8px;
    background-color: #F94D1D;
    color: white;
    padding: 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }


  .google-login-tooltip {
    display: none;
  }

  .sign-in-container {
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  @media (max-width: 991px) {
    .google-login-tooltip {
      
      display: block;
      background-color: #333;
      color: #fff;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 10px;
      width: 250px;
      text-align: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }



  .signInput:-webkit-autofill,
  .signInput:-webkit-autofill,
.signInput:-webkit-autofill:hover, 
.signInput:-webkit-autofill:focus, 
.signInput:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #121717 inset !important;
    -webkit-text-fill-color: #ffffff;
}
