.options {
    input, button {
        height: 60px;
    }
    input {
        color: black;
    }
    input:focus {
        color: black;
    }
    button {
        background: none;
    }

    ul {
        list-style: none;
    }
    max-height: 1px; 
    
}