
.info__area {
    background: $text-color-8;
    
    &-title {
        text-align: center;
    }
    &-item {
        &-image {
            position: relative;
            overflow: hidden;
            img {
                transform: scale(1);  
                transition: all 0.4s ease-out 0s;
            }
            &:hover img {
                transform: scale(1.1);                  
            }
        }
        &-content {
            padding-top: 20px;
            &-meta {
                margin-bottom: 20px;
                ul {
                    margin: 0;
                    padding: 0;
                    li {
                        list-style: none;
                        display: inline-block;
                        margin-right: 15px;
                        padding-right: 20px;
                        border-right: 1px solid $text-color-2;
                        &:last-child {
                            margin: 0;
                            padding: 0;
                            border: 0;
                        }
                        a {
                            color: $body-color;
                            transition: all 0.4s ease-out 0s;
                            // text-transform: capitalize;
                            font-weight: 500;
                            i::before {
                                color: $primary-color;
                                margin-right: 10px;
                            }
                            &:hover {
                                color: $primary-color;
                            } 
                        }
                    }
                }
            }
            h4 {
                margin-bottom: 20px;
                a {
                    transition: all 0.4s ease-out 0s;
                    &:hover {
                        color: $primary-color;
                    }
                }
            }
            .simple-btn {
                color: $text-color-4;
                &:hover {
                    color: $primary-color;
                }
            }
        }
    }
}

/*==========================================================================
info Details
==========================================================================*/
.info__details {
    background: $text-color-8;
    .banner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 21/9;
        background-color: #00171f;
        overflow: hidden;
      
        h1 {
          position: relative;
          font-size: 2rem;
          color: #fff;
          z-index: 1;
      
          @media (min-width: 768px) {
            font-size: 2.5rem;
          }
      
          @media (min-width: 1200px) {
            font-size: 3rem;
          }
        }
      
        .square-one,
        .square-two {
          position: absolute;
          width: 30%;
          background-color: #007ea7;
        }
      
        .square-one {
          bottom: 0;
          left: 0;
          aspect-ratio: 5/4;
        }
      
        .square-two {
          top: 0;
          right: 0;
          width: 80%;
          aspect-ratio: 7;
          background-color: #003459;
        }
      }
    &-left {
        &-meta {
            padding: 30px 0 25px 0;
            margin-bottom: 30px;
            ul {
                padding: 0;
                margin: 0;
                li {
                    display: inline-block;
                    margin-right: 30px;
                    padding-right: 30px;
                    border-right: 1px solid $border3;
                    a {
                        transition: all .4s ease-out 0s;
                        i {
                            color: $primary-color;
                            margin-right: 10px;
                        }
                        &:hover {
                            color: $primary-color;
                        } 
                    }
                    &:last-child {
                        margin: 0;
                        padding: 0;
                        border: 0;
                    }
                }
            }
        }
        &-box {
            background: $text-color-1;
            padding: 50px 30px;
            position: relative;
            margin: 30px 0;
            &-icon {
                position: absolute;
                max-width: 180px;
                bottom: 60px;
                right: 60px;
            }
            p {
                font-size: 22px;
                line-height: 32px;
                text-transform: none;
                color: $text-color-4;
                margin-bottom: 20px;
            }
            h6 {
                position: relative;
                display: inline-block;
                padding-left: 60px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 50px;
                    height: 2px;
                    left: 0;
                    top: 14px;
                    background: $primary-color;                        
                }
            }
        }
        &-related {
            margin: 70px 0;
            h6 {
                margin-right: 30px;
            }
            &-tag {
                display: inline-flex;
                align-items: baseline;
                ul {
                    padding: 0;
                    margin: 0;
                    li {
                        display: inline-block;
                        margin-right: 20px;
                        a {
                            transition: all 0.4s ease-out 0s;
                            color: $text-color-4;
                            &:hover {
                                color: $primary-color;
                            }                         
                        }
                    }
                }
            }
            &-share {
                ul {
                    padding: 0;
                    margin: 0;
                    li {
                        display: inline-block;
                        list-style: none;
                        margin-right: 15px;
                        a i {
                            transition: all 0.3s ease-out 0s;
                            &:hover {
                                color: $primary-color;
                            }
                        }
                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
        &-post {
            &-author {
                padding: 30px;
                background: $text-color-1;
                overflow: hidden;
                &-image {
                    float: left;
                    img {
                        max-width: 130px;
                        border-radius: 50%;
                    }
                }
                &-content {
                    overflow: hidden;                    
                    padding-left: 30px;
                    padding-top: 6px;
                    h5 {
                        margin-bottom: 10px;
                    }
                }
            }
        }
        &-comment {
            &-item {
                border-bottom: 1px solid $border3;
                padding-bottom: 30px;
                margin-bottom: 30px;
                &-comment {
                    &-image {
                        float: left;
                        img {
                            max-width: 100px;
                            border-radius: 50%;
                        }
                    }
                    &-content {
                        padding-left: 30px;
                        overflow: hidden;
                        span {
                            font-size: 14px;
                            margin-bottom: 15px;
                            display: inline-block;
                        }
                        h5 {
                            a {
                                float: right;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 40px;                                
                                border: 1px solid $border3;
                                transition: all 0.4s ease-out 0s;
                                padding: 0 20px;
                                i {
                                    margin-right: 10px;
                                }
                                &:hover {
                                    background: $primary-color;
                                    color: $white;
                                    border-color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-contact {
            &-form {
                &-item {
                    position: relative;
                    i {
                        position: absolute;
                        right: 20px;
                        top: 23px;
                        color: $primary-color;
                    }
                    input {
                        font-size: 14px;
                        background: transparent;
                    }
                    textarea {
                        height: 220px;
                        font-size: 14px;
                        background: transparent;
                    }
                    .theme-btn-1 {
                        i {
                            position: initial;
                            color: $white;
                            transition: all .4s ease-out 0s;
                        }
                        &:hover i {
                            color: $text-color-1;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 575px) {
    .info__details {
        &-left {
            &-meta {
                ul {
                    li {
                        margin-right: 20px;
                        padding-right: 20px;
                    }
                }
            }
            &-box {
                &-icon {
                    max-width: 100px;
                    bottom: 30px;
                    right: 30px;
                }
                p {
                    font-size: 16px;
                    line-height: 26px;
                }
            }
            &-post {
                &-author {
                    &-image {
                        float: initial;
                        margin-bottom: 20px;
                    }
                    &-content {                
                        padding: 0;
                    }
                }
            }
            &-comment {
                &-item {
                    margin-left: 0;
                    &-comment {
                        &-image {
                            float: initial;
                            margin-bottom: 20px;
                        }
                        &-content {                
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}
