.header__area {
  padding: 0 50px;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 9999;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: $white;
    opacity: 0.15;
    z-index: 1;
    height: 1px;
  }
  &-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 90px;
    &-logo {
      a {
        display: inline-block;
        img {
          max-width: 180px;
          z-index: 999;
          position: relative;
        }
      }
    }
    &-menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-contact {
      display: flex;
      align-items: center;
      margin-left: 70px;
      .userName {
        font-weight: 700;
        transition: 0.3s;
        font-size: 18px;
        color: $white;
        &:hover {
          color: $primary-color;
        }
      }

      &-icon {
        margin-right: 20px;
        i {
          width: 60px;
          height: 60px;
          line-height: 60px;
          display: inline-block;
          font-size: 25px;
          text-align: center;
          border-radius: 50%;
          border: 1px solid $text-color-3;
          color: $primary-color;
        }
      }
      &-content {
        span {
          color: $text-color-4;
          font-weight: 500;
          font-family: $heading-font;
        }
        h6 {
          a {
            transition: all 0.4s ease-out 0s;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {



  .header__area-box-main-menu {
    ul {
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        position: relative;
        list-style: none;
        padding: 0;
        margin: 0;
        &:hover > a {
          color: $primary-color;
        }
        &:hover > .sub-menu {
          opacity: 1;
          visibility: visible;
          transform: scale(1, 1);
        }
        .sub-menu {
          position: absolute;
          left: 0;
          right: 0;
          top: 100%;
          border-radius: 0;
          display: block;
          visibility: hidden;
          min-width: 235px;
          margin: 0;
          transform: scale(1, 0);
          transform-origin: 0 0;
          background: $white;
          transition: all 0.4s ease-out 0s;
          box-shadow: 0px 15px 30px 0px $shadow-1;
          opacity: 0;
          z-index: 9;
          li {
            display: block;
            margin: 0;
            border-bottom: 1px solid $body-color;
            a {
              color: $text-color-1;
              padding: 12px 20px;
              font-size: 16px;
              // text-transform: capitalize;
              font-weight: 500;
              position: relative;
              &::after {
                position: absolute;
                content: "";
                background: $primary-color;
                width: 2px;
                transition: all 0.5s ease-out 0s;
                height: 0;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            .sub-menu {
              left: 100%;
              top: 0;
            }
            &:hover > a {
              color: $primary-color;
              padding-left: 25px;
              &::after {
                height: 100%;
              }
            }
          }
          li:last-child {
            border: none;
          }
        }
        .lang_box_width {
          min-width: 50px !important;
        }
        ul {
          .sub-menu li .sub-menu {
            color: $primary-color;
            cursor: pointer;
          }
        }
        a {
          color: $white;
          display: block;
          font-size: 15px;
          line-height: 28px;
          font-weight: 500;
          padding: 55px 30px 55px 15px;
          text-transform: uppercase;
          font-family: $heading-font;
          transition: all 0.4s ease;
        }
        &.menu-item-has-children > {
          a {
            position: relative;
            &::before {
              content: "";
              position: absolute;
              top: 51%;
              transform: translateY(-50%);
              right: 17px;
              width: 2px;
              height: 8px;
              background: $white;
              transition: all 0.4s ease;
            }
            &::after {
              content: "";
              position: absolute;
              top: 51%;
              transform: translateY(-50%);
              right: 14px;
              width: 8px;
              height: 2px;
              background: $white;
            }
          }
        }
        &.menu-item-has-children {
          &:hover > a {
            &::before {
              transform: translateY(-50%) rotate(90deg);
              opacity: 0;
            }
            &::after {
              background: $primary-color;
            }
          }
        }
      }
    }
  }
  .mobile__menu {
    display: flex !important;
  }
  .mobile-menu,
  .mobile-menu-toggle {
    display: none;
  }

  .lang-mobile {
    display: none !important;
  }
  .lang-pc {
    display: block !important;
  }


}
.header__sticky {
  width: 100%;
  z-index: 999;
  transition: all 0.4s ease;
  &-active {
    position: fixed !important;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    box-shadow: $shadow-3;
    background: $text-color-1 !important;
    animation: header_sticky 1.1s;
    display: block;
    &.header__area::after {
      display: none;
    }
  }
  @keyframes header_sticky {
    0% {
      top: -250px;
    }
    100% {
      top: 0;
    }
  }
}
/*==========================================================================
Menu Box CSS
==========================================================================*/
.contact__info {
  margin-top: 30px;
  &-list {
    a {
      display: flex;
      align-items: center;
      color: $body-color;
      margin-bottom: 20px;
      transition: all 0.4s ease-out 0s;
      &:last-child {
        margin: 0;
      }
      i {
        color: $primary-color;
        display: block;
        width: 40px;
        line-height: 40px;
        height: 40px;
        border: 1px solid $text-color-2;
        text-align: center;
        border-radius: 5px;
        margin-right: 20px;
      }
      &:hover {
        color: $primary-color;
      }
    }
  }
}

.side__menu-toggle {
  position: absolute;
  right: 0;
  top: 22px;
  cursor: pointer;
  background-image: url("../../icon/up-arrow.png");
  width: 13px;
  height: 13px;
  background-size: 13px;
  &.active {
    transform: rotate(-180deg);
  }
}
.side__menu {
  padding: 0;
  margin: 0;
  list-style: none;
}
.side__menu {
  li {
    a {
      padding: 15px 0;
      display: block;
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      transition: all 0.4s ease;
    }
    &:hover > a {
      color: $primary-color;
    }
  }
  ul {
    padding: 0;
    list-style: none;
    display: none;
    margin-left: 15px;
  }
}
.side__menu .side__menu-toggle.active + ul {
  display: block;
}
.menu-item-children {
  position: relative;
}



@media (max-width: 1199px) {
  .header__area {
    &-box {
      &-contact {
        margin-right: 50px;
      }
    }
  }
}
@media (max-width: 1199px) {


  .lang-mobile {
    display: block !important;
  }
  .lang-pc {
    display: none !important;
  }
  

  .header__area {
    &-box {
      position: relative;
      padding: 35px 0;
      &-sidebar {
        display: none;
      }
    }
  }
  .mobile-menu-toggle {
    position: absolute;
    height: 57px;
    width: 57px;
    right: 0;
    top: 0;
    background: $primary-color;
    cursor: pointer;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 2px;
      width: 10px;
      background-color: white;
      transition: all 0.3s ease;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(90deg);
    }
    &.active {
      &:before {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }
  .mobile__menu {
    position: absolute;
    width: 100%;
    left: 0;
    background: $text-color-7;
    display: none;
    top: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    li a {
      color: white;
      font-size: 16px;
      line-height: 26px;
      font-weight: 600;
      // text-transform: capitalize;
      border-top: 1px solid #3f4144;
      opacity: 1;
      display: block;
      padding: 15px 35px;
      transition: 0.4s;
    }
    ul {
      padding: 0;
      display: none;
      list-style: none;
    }
    .mobile-menu-toggle.active + ul {
      display: block;
    }
  }
  .sub-menu li a {
    padding-left: 65px;
  }
  .menu-item-has-children {
    position: relative;
  }
  .mobile-menu {
    display: inline-block;
    width: 25px;
    height: 27px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0px;
    margin-top: -12px;
    span,
    span:before,
    span:after {
      width: 100%;
      height: 3px;
      background: white;
      display: block;
    }
    span {
      margin: 0 auto;
      position: relative;
      top: 12px;
      transition-duration: 0s;
      transition-delay: 0.2s;
      &:before {
        content: "";
        position: absolute;
        margin-top: -9px;
        transition-property: margin, transform;
        transition-duration: 0.2s;
        transition-delay: 0.2s, 0s;
      }
      &:after {
        content: "";
        position: absolute;
        margin-top: 9px;
        transition-property: margin, transform;
        transition-duration: 0.2s;
        transition-delay: 0.2s, 0s;
      }
    }
    &-active {
      span {
        background-color: rgba(0, 0, 0, 0);
        transition-delay: 0.2s;
        &:before {
          margin-top: 0;
          transform: rotate(45deg);
          transition-delay: 0s, 0.2s;
        }
        &:after {
          margin-top: 0;
          transform: rotate(-45deg);
          transition-delay: 0s, 0.2s;
        }
      }
    }
  }
}

.menu__user-action {
  display: flex;
  flex-direction: row;
}


.lang {
  cursor: pointer;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  padding: 55px 30px;
  text-transform: uppercase;
  font-family: "Ubuntu", sans-serif;
  .sub-menu {
    color: black;
    transition: all 0.4s ease-out 0s;
    li:hover {
      color: $primary-color;
    }
  }
}

@media (max-width: 767px) {
  .header__area {
    padding: 0;
    &-box {
      &-logo {
        &.center {
          a {
            padding: 56px 20px 0px 20px;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 160px;
              height: 190px;
              border-radius: 60px;
              background: $text-color-8;
              z-index: 2;
            }
            img {
              max-width: 120px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .header__area {
    padding: 0;
    &-box {
      &-logo {
        a {
          img {
            max-width: 175px;
          }
        }
        &.center {
          a {
            padding: 34px 20px 0px 20px;
            &::after {
              width: 140px;
              height: 135px;
              border-radius: 40px;
            }
            img {
              max-width: 100px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .header__area {
    &-box {
      &-contact {
        .userName {
          font-size: 14px;
        }
      }
    }
  }
}

.header_name, .header_name li {
  list-style: none;
}


.header_name_menu {
  display: none !important;
}

.header_name_submenu {
  display: flex !important;
}

@media (max-width: 1199px) {
  .header_name_menu {
    display: block !important;
  }
  
  .header_name_submenu {
    display: none !important;
  }
}