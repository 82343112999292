$color-orange: #F94D1D;
$color-black: #6F7173;
$color-background: #121717;
$color-text: #9FA0A1;

.messages-container {
  background-color: $color-background;
  color: $color-text;
  font-family: 'Arial', sans-serif;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;

  h2 {
    color: $color-orange;
    // margin-bottom: 10px;
    font-size: 20px;
  }

  p {
    margin-bottom: 15px;
    line-height: 1.5;
  }

  .message-item {
    padding: 15px;
    background-color: $text-color-7;
    margin-bottom: 10px;
    border-radius: 8px;

    position: relative;

    .delete-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: transparent;
      border: none;
      font-size: 18px;
      cursor: pointer;
      color: $color-orange;
      &:hover {
        color: darken($color-orange, 10%);
      }
    }

    &.was-expanded {
      background-color: $text-color-8;
    }

    &:hover {
      background-color: darken($text-color-7, 1%);
    }
  }
}