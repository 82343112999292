.admin {
    .row {
        --bs-gutter-x: 0;
    }
}

.section-tab {
    background-color: $text-color-7;
    display: flex;
    flex-direction: column;

    .tabSelector {
        padding: 10px;
        transition: 0.3s;

        &:hover {
            background-color: $text-color-6;
            cursor: pointer;

        }
        &.active {
            background-color: $primary-color;
            color: $white;
        }


        
    }
}
